import React, { useState } from 'react';
import {Button} from "../Atoms/Buttons/Button";



function StartWork() {
    
    
    return (
        
            <form name="Начать работу"  method="post" action="/thankyou/" netlify-honeypot="bo-field" data-netlify="true" >
                <input type="hidden" name="bo-field" />
                <input type="hidden" name="form-name" value="Начать работу" />
                <input type="hidden" name="Начать работу" value="Начать работу"/>
                <input type="text" name="name" placeholder="Как к вам обращаться?" required/>
                <input type="tel" name="phone" placeholder="Введите номер телефона" required/>
                <Button primary type="submit">Отправить заявку</Button>
            </form>
        
    );
}

export default StartWork;